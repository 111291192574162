'use client'

import { useState, useEffect } from 'react'

const useDeviceSize = defaultDeviceSize => {
  const [deviceSize, setDeviceSize] = useState(defaultDeviceSize)

  const getDeviceSize = () => {
    if (window.matchMedia('(min-width: 1024px)').matches) return 'desktop'
    // TODO: pending especific design for tablet
    // if (window.matchMedia('(min-width: 768px)').matches) return 'tablet'
    if (window.matchMedia('(min-width: 0px)').matches) return 'mobile'
  }

  useEffect(() => {
    const handleResize = () => {
      const currentDeviceSize = getDeviceSize()
      setDeviceSize(currentDeviceSize)
      // document.cookie = `device-size=${JSON.stringify(currentDeviceSize)}`
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [deviceSize])

  useEffect(() => {
    if (deviceSize === 'mobile') {
      document.cookie = 'isMobile=true'
      return
    }
    // TODO: pending especific design for tablet
    // if (deviceSize === 'tablet') {
    //   document.cookie = 'isTablet=true'
    //   return
    // }
    document.cookie = 'isDesktop=true'
    document.cookie = 'isMobile=false'
    // document.cookie = 'isTablet=false'
  }, [deviceSize])

  return {
    isMobile: deviceSize === 'mobile',
    // isTablet: deviceSize === 'tablet',
    isDesktop: deviceSize === 'desktop',
  }
}

export { useDeviceSize }
