'use client'

import { QueryClientProvider } from '@tanstack/react-query'
import queryClientContext from '@/contexts/QueryClientContext'
import { AppProvider } from '@/contexts/AppContext'
import { useEffect } from 'react'
import { IDA_TOKEN } from '@/constants/constant'
import { useRouter } from 'next/navigation'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { EventBusProvider } from '@/contexts/EventBusContext'
import { useDeviceSize } from '@/hooks/useDeviceSize'

export default function ClientLayout({ children }) {
  const router = useRouter()

  useDeviceSize({ defaultDeviceSize: 'desktop' })

  useEffect(() => {
    // Check if the token is present in localStorage
    const token = localStorage.getItem(IDA_TOKEN)
    if (token) {
      if (window.location.pathname === '/') {
        // Token exists, redirect to the chat dashboard page
        router.replace('/chat')
      }
    }
  }, [])

  return (
    <QueryClientProvider client={queryClientContext}>
      <EventBusProvider>
        <AppProvider>{children}</AppProvider>
      </EventBusProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
