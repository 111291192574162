'use client'

import { useState, createContext, useContext, useMemo } from 'react'

// Create a simple event bus using React's context and hooks
const EventBusContext = createContext()

export function EventBusProvider({ children }) {
  const [eventListeners] = useState({})

  // Subscribe to an event
  const subscribe = (eventName, callback) => {
    if (!eventListeners[eventName]) {
      eventListeners[eventName] = []
    }
    eventListeners[eventName].push(callback)
  }

  // Unsubscribe from an event
  const unsubscribe = (eventName, callback) => {
    if (eventListeners[eventName]) {
      eventListeners[eventName] = eventListeners[eventName].filter(
        listener => listener !== callback,
      )
    }
  }

  // Publish an event
  const publish = (eventName, data) => {
    if (eventListeners[eventName]) {
      eventListeners[eventName].forEach(listener => {
        listener(data)
      })
    }
  }

  const contextValue = useMemo(
    () => ({ subscribe, unsubscribe, publish }),
    [subscribe, unsubscribe, publish],
  )
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <EventBusContext.Provider value={contextValue}>
      {children}
    </EventBusContext.Provider>
  )
}

export function useEventBus() {
  return useContext(EventBusContext)
}
